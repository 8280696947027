.alertDiv {
  position: absolute !important;
  width: 100%;
  text-align: center;
  opacity: 1 !important;
  padding: 30px 0 !important;
  font-size: 20px;
  z-index: 2;
}

.alert-success {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
}

.alert-danger {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
}

@media (max-width: 1366px) {
  .alertDiv {
    position: relative !important;
    text-align: left;
    padding: 15px !important;
    font-size: 18px;
    margin-bottom: 0 !important;
  }
}

.alert-dismissible .close {
  z-index: 2 !important;
  position: absolute;
  top: 0 !important;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
  font-size: 45px !important;
  font-weight: 700 !important;
  border: none !important;
  background: none;
}

.alert-dismissible .close span.sr-only {
  font-size: 16px !important;
  margin-top: 5px;
  margin-left: 10px;
  display: none;
}

.alert-dismissible button.close {
  display: flex;
  align-items: center;
}

@media (max-width: 1366px) {
  .alert-dismissible .close {
    position: relative !important;
    top: -7px !important;
    padding: 0 !important;
    font-size: 32px;
  }
}

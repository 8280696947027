.adminOptionsDiv {
  text-align: center;
  padding-top: 60px;
  padding-left: 90px;
}

@media (max-width: 1366px) {
  .adminOptionsDiv {
    padding-top: 60px;
    padding-left: 0;
  }
}

.adminHeaderDiv {
  display: flex;
  align-items: center;
}

.adminHeaderDivButton {
  margin-left: 20px;
}

.adminOptionsBtnDiv {
  margin-top: 30px;
  margin-bottom: 60px;
}

.adminOptionBtn {
  margin: auto 20px;
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: 200px;
  padding: 10px 0 !important;
  font-size: 16px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
}

.adminOptionBtn:hover {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  transition-duration: 0.4s;
}

.adminOptionBtn:disabled {
  cursor: not-allowed;
  background-color: #ff7f32 !important;
  color: #ffffff !important;
}

.adminAgentOptionBtn {
  margin: auto 20px auto 0;
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: 200px;
  padding: 10px 0 !important;
  font-size: 16px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
}

.adminAgentOptionBtn:hover {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  transition-duration: 0.4s;
}

.adminAgentOptionBtn:disabled {
  cursor: not-allowed;
  background-color: #ff7f32 !important;
  color: #ffffff !important;
}

@media (max-width: 1366px) {
  .adminOptionBtn,
  .adminOptionDenyBtn {
    margin: 15px 30px;
  }
}

.adminOptionDenyBtn {
  margin: auto 20px;
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: 200px;
  padding: 10px 0 !important;
  font-size: 16px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
}

.adminOptionDenyBtn:hover {
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  transition-duration: 0.4s;
}

.adminOptionDenyBtn:disabled {
  cursor: not-allowed;
  background-color: #ff7f32 !important;
  color: #ffffff !important;
}

.adminRow {
  /* width: 75%; */
  margin: auto !important;
  padding-bottom: 60px;
}

.adminRow > * {
  padding: 0 !important;
}

.adminSectionBorder {
  /* border: 1px solid #b5bbce; */
}

@media (max-width: 1366px) {
  .adminRow {
    width: 100% !important;
  }
}

.rdw-editor-main {
  height: 300px;
  border: 1px solid #b5bbce;
  border-radius: 5px;
}

.public-DraftEditor-content[contenteditable="true"] {
  padding-left: 30px;
  padding-right: 30px;
  border: none !important;
}

.fileButtonDiv {
  display: flex;
  align-items: baseline;
}

.fileCloseButton {
  background: none !important;
  border: none !important;
  color: #ff7f32 !important;
  padding: 0 !important;
  margin-left: 60px !important;
  margin-top: -10px !important;
  font-size: 18px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
  transition-duration: 0.4s;
}

.fileCloseButton:hover {
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  transition-duration: 0.4s;
}

.fileCloseButton:disabled {
  cursor: not-allowed;
}

.groupDeleteButton {
  background: none !important;
  border: none !important;
  color: #ff7f32 !important;
  padding: 0 !important;
  margin-right: 30px !important;
  margin-top: -10px !important;
  font-size: 18px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
  transition-duration: 0.4s;
}

.groupDeleteButton:hover {
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  transition-duration: 0.4s;
}

.groupDeleteButton:disabled {
  cursor: not-allowed;
}

.groupDeleteText {
  display: flex;
  align-items: baseline;
}

.fileButtonName {
  width: 300px;
  word-break: break-word;
  margin: 0 0 0 15px !important;
}

.inputErrorMessage {
  color: red;
  margin: -15px 0 15px 0;
}

.activeButton {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
}

.docTypeBtnGroup {
  margin-bottom: 20px;
  display: block !important;
}

.docTypeBtnGroupName {
  margin-right: 30px;
}

.docTypeBtn {
  margin: 0 !important;
  background: none !important;
  color: #ff7f32 !important;
  border: 1px solid #ff7f32 !important;
  /* line-height: 0 !important; */
  outline: none !important;
  outline-style: none !important;
}

.docTypeBtnActive {
  background: #ff7f32 !important;
  color: #ffffff !important;
}

.fileCol {
  padding: 0 !important;
  margin-bottom: 15px !important;
}

.existingFilesHeader {
  margin-top: 15px;
}

/* Table layout */

/* .adminTable {
  overflow-x: auto !important;
} */

.react-bootstrap-table {
  table-layout: auto !important;
  border: 1px solid #b5bbce !important;
  overflow-x: auto !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.tableHeader {
  text-align: center !important;
}

.agentList {
  display: flex;
  align-items: baseline;
}

/* Audit Section */

.auditLogRow {
  border: 1px solid #b5bbce;
  align-items: center;
  padding: 15px 30px;
  width: 70%;
}

.auditLogVisitorsRow {
  border: 1px solid #b5bbce;
  align-items: center;
  padding: 15px 30px;
  width: 75%;
}

.auditLogCol1,
.auditLogCol2 {
  /* border: 1px solid #b5bbce; */
}

.auditLogCol3 {
  text-align: right;
}

.auditLogRow > .auditLogCol > .auditLogDiv {
  display: table;
}

.auditLogRow > .auditLogCol > .auditLogDiv2 {
  margin: auto;
}

.auditLogRow > .auditLogCol > .auditLogDiv3 {
  margin-right: 0;
  margin-left: auto;
}

.auditLogRow > .auditLogCol > .auditLogDiv > p {
  /* text-align: left; */
}

.auditLogDiv {
  align-items: center;
}

.auditLogDiv > * {
  margin: auto;
}

.auditLogDivCenter {
  text-align: center;
}

.auditButton {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  /* width: 200px !important; */
  padding: 10px 30px !important;
  font-size: 16px !important;
  font-family: "transat-textate", sans-serif;
  font-weight: 700 !important;
  transition-duration: 0.4s;
  text-decoration: none !important;
}

.auditButton:hover {
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  transition-duration: 0.4s;
}

.auditViewVisitorsLButton {
  text-decoration: none;
  color: #ff7f32;
  display: block;
  text-align: center;
  margin-top: 15px;
  font-weight: 700;
  font-size: 16px;
}

.pagination {
  align-items: center;
  font-size: 16px;
  font-family: "transat-textate", sans-serif;
  font-weight: 700;
  margin-top: 30px;
}

.paginationPage,
.paginationPageBackNext {
  padding: 15px !important;
}

.paginationPageBackNext {
  text-decoration: none;
  color: #ff7f32;
}

.paginationPageActive {
  background-color: #ff7f32;
  color: #ffffff;
}

/* Sort Section */

.adminSortHeader {
  padding-right: 15px;
  font-size: 16px;
}

.adminSortButton {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: 1px solid #ff7f32 !important;
  padding: 5px 10px !important;
  margin-right: 5px;
  transition-duration: 0.4s;
}

.adminSortButton:hover {
  color: #ff7f32 !important;
  background-color: transparent !important;
  transition-duration: 0.4s;
}

.adminSortButtonActive {
  color: #ff7f32 !important;
  background-color: transparent !important;
}

/* Visitor Details */

.visitorDetailsModal {
  max-width: 50% !important;
  /* transform: translate(0, 0) !important; */
}

.visitorLabel {
  margin-bottom: 0;
  overflow-wrap: break-word;
}

.visitorInfo {
  overflow-wrap: break-word;
}

.visitorInfoNotes {
  /* line-height: 0%; */
  margin-top: 10px;
}

.searchBarHeader {
  font-size: 16px;
}

.page-link {
  /* border-color: #FF7F32 !important; */
  border: none !important;
  color: #ff7f32 !important;
}

.page-item.active .page-link {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
}

button#pageDropDown {
  background-color: #ff7f32 !important;
}

li.dropdown-item > a {
  color: #ff7f32 !important;
  text-decoration: none !important;
}

.mainContainerAdmin {
  padding-bottom: 140px !important;
}

.stars {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 350px;
  padding: 15px;
  z-index: 2;
}

@media (max-width: 1366px) {
  .stars {
    display: none;
  }
}

.homeRow {
  /* height: 80%; */
  flex-wrap: wrap;
}

@media (min-width: 1366px) {
  .homeCol1Div {
    padding-right: 140px;
  }
}

.forgotPasswordLink {
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  color: #707070;
  text-decoration: underline;
}

#background {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(30, 43, 89, 0.04);
  z-index: 1;
}

#redBorderLine {
  border-right: 1px solid #ff7f32;
  height: 100vh;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 140px;
}

@media (max-width: 1365px) {
  #background {
    display: none;
  }
  #redBorderLine {
    display: none;
  }
  .homeCol2 {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: rgba(30, 43, 89, 0.04);
  }
}

@media (min-width: 1365px) {
  .homeCol2 {
    padding-left: 140px !important;
  }
}

.homeCol2 {
  z-index: 2;
}

.requestAccessText {
  margin-top: 15px;
}

@media (max-width: 1366px) {
  .requestAccessText {
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .homeDisclaimerText {
    position: absolute;
    bottom: 30px;
  }
}

@media (max-width: 1440px) {
  .homeDisclaimerText {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

.thankyouImg {
  width: 125px;
  height: auto;
  margin: auto;
  display: block;
}

@media (max-width: 1366px) {
  .thankyouImg {
    width: 75px;
    margin-top: 60px;
  }
}

.thankyouEmailDiv {
  margin: 0 auto 30px auto;
  text-align: center;
}

@media (min-width: 1366px) {
  .thankyouEmailDiv {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: 140px;
  }
}

@media (max-width: 1366px) {
  .thankyouEmailDiv {
    padding-bottom: 60px;
    margin-top: 30px;
  }
}

.thankyouEmailText {
  margin: 0;
}

.thankyouEmailLink {
  font-size: 20px;
  color: #ff7f32;
  text-decoration: none !important;
}

/* Email Form */

.thankYouEmailForm {
  margin: auto;
  width: 35%;
}

.email_verifiedText {
  color: green;
  text-align: center;
  font-size: 18px;
  width: 50%;
  margin: 15px auto 0 auto;
}

@media (max-width: 768px) {
  .email_verifiedText {
    width: 100%;
  }
}

.email_verifiedLoginDiv {
  text-align: center;
}

@media (min-width: 768px) {
  .email_verifiedLoginDiv {
    padding-top: 60px;
  }
}

@media (max-width: 768px) {
  .email_verifiedLoginDiv {
    padding: 30px 0 60px 0;
  }
}

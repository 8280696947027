/* Regular Footer */

.footerDiv {
  background-color: #002855;
  padding: 90px 140px;
}

@media (max-width: 1440px) {
  .footerDiv {
    padding: 60px 30px;
  }
}

.footerText {
  color: #ffffff;
  width: 100%;
}

@media (max-width: 1440px) {
  .footerText {
    width: 100%;
  }
}

.footerLogo {
  width: 200px;
  height: auto;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .footerLogo {
    width: 150px;
    margin: 15px auto 0 auto;
    display: inline-block;
  }
}

.stars2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 350px;
  padding: 15px;
  z-index: 2;
}

@media (max-width: 768px) {
  .stars2 {
    display: none;
  }
}

/* Dashboard Footer */

.dashboardFooterDiv {
  padding: 90px 140px;
}

@media (max-width: 1440px) {
  .dashboardFooterDiv {
    padding: 60px 30px;
  }
}

.dashboardFooterText {
  width: 100%;
}

@media (max-width: 768px) {
  .dashboardFooterText {
    width: 100%;
  }
}

.dashboardStars {
  position: absolute;
  top: -60px;
  right: 0;
  width: 350px;
  padding: 15px;
  z-index: 10;
}

@media (max-width: 768px) {
  .dashboardStars {
    display: none;
  }
}

.footerDiv,
.dashboardFooterDiv {
  overflow-y: auto;
  height: 300px;
}

/* Main */

.overflowXHidden {
  overflow-x: hidden !important;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.displayTable {
  display: table;
}

.displayTableCell {
  display: table-cell;
  vertical-align: middle;
}

.displayNone {
  display: none;
  transition: visibility 0s, opacity 0.5s linear;
}

.displayBlock {
  display: block;
}

.blur {
  text-shadow: 0 0 10px #000000;
  color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.hoverDelay {
  transition-duration: 0.4s;
}

.passwordError {
  color: #ff7f32;
}

.bold {
  font-weight: 700 !important;
}

.heavyBold {
  font-weight: 900;
}

.ivyPrestoItalic {
  font-family: "ivypresto-display", serif;
  font-weight: 400;
  font-style: italic;
}

.ivyPrestoRegular {
  font-family: "ivypresto-display", serif;
  font-weight: 400;
  font-style: normal;
}

.ivyPrestoBold {
  font-family: "ivypresto-display", serif;
  font-weight: 700;
  font-style: normal;
}

.ivyPrestoBoldItalic {
  font-family: "ivypresto-display", serif;
  font-weight: 700;
  font-style: italic;
}

.copperPlate {
  font-family: "Copperplate", sans-serif;
  font-weight: 300 !important;
}

.copperPlateBold {
  font-family: "Copperplate", sans-serif;
  font-weight: 700 !important;
}

@font-face {
  font-family: "roboto", sans-serif;
  src: url("../font/roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "roboto", sans-serif;
  src: url("../font/roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "oswald", sans-serif;
  src: url("../font/oswald/Oswald-Regular.ttf");
}

@font-face {
  font-family: "oswald", sans-serif;
  src: url("../font/oswald/Oswald-Bold.ttf");
}

.robotoFontRegular {
  font-family: "roboto", sans-serif;
  font-weight: 400 !important;
}

.robotoFontBold {
  font-family: "roboto", sans-serif;
  font-weight: 700 !important;
}

.robotoFontBoldItalic {
  font-family: "roboto", sans-serif;
  font-weight: 700 !important;
}

.oswaldFontRegular {
  font-family: "oswald", sans-serif;
  font-weight: 400 !important;
}

.oswaldFontBold {
  font-family: "oswald", sans-serif;
  font-weight: 700 !important;
}

.oswaldFontBoldItalic {
  font-family: "oswald", sans-serif;
  font-weight: 700 !important;
}

/* .transatFontRegular {
  font-family: "transat-text", sans-serif;
  font-weight: 400 !important;
}

.transatFontBold {
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
}

.transatFontBoldItalic {
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
} */

@font-face {
  font-family: "Georgia", sans-serif;
  src: url("../font/georgia/georgiaRegular.ttf");
}

.georgiaRegular {
  font-family: "Georgia", sans-serif;
  font-weight: 400 !important;
}

@font-face {
  font-family: "Georgia", sans-serif;
  src: url("../font/georgia/georgiaRegular.ttf");
}

.georgiaItalic {
  font-family: "Georgia", sans-serif;
  font-weight: 400 !important;
  font-style: italic;
}

@font-face {
  font-family: "Georgia", sans-serif;
  src: url("../font/georgia/georgiaBold.ttf");
}

.georgiaBold {
  font-family: "Georgia", sans-serif;
  font-weight: 700 !important;
}

.arial {
  font-family: "Arial", sans-serif;
  font-weight: 400;
}

.generalHeader {
  color: #2b365f;
  font-size: 60px;
  font-family: "roboto", serif;
  font-weight: 400;
  font-style: normal;
}

/* .generalHeader {
  color: #2b365f;
  font-size: 60px;
  font-family: "transat-text", serif;
  font-weight: 400;
  font-style: normal;
} */

@media (max-width: 1366px) {
  .generalHeader {
    font-size: 36px;
  }
}

.mainContainer {
  padding: 0 175px 0 140px;
}

@media (max-width: 1366px) {
  .mainContainer {
    padding: 0 30px;
  }
}

@media (min-width: 1366px) {
  .mainContainerHeight {
    height: 100vh;
  }
}

.headerSpacer {
  padding: 10px;
  display: block;
}

.generalButton {
  background-color: transparent !important;
  color: #ff7f32 !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: 200px;
  padding: 10px 0 !important;
  font-size: 16px !important;
  font-family: "roboto", sans-serif !important;
  font-weight: 700 !important;
  transition-duration: 0.4s;
}

.generalButton:hover {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  transition-duration: 0.4s;
}

.generalButtonInverted {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: 200px;
  padding: 10px 0 !important;
  font-size: 16px !important;
  font-family: "roboto", sans-serif !important;
  font-weight: 700 !important;
  transition-duration: 0.4s;
}

.generalButtonInverted:hover {
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  transition-duration: 0.4s;
}

@media (max-width: 1366px) {
  .generalButton,
  .generalButtonInverted {
    /* width: 150px !important; */
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .generalButton,
  .generalButtonInverted {
    width: 100% !important;
    font-size: 14px !important;
  }
}

.generalButton:disabled,
.generalButtonInverted:disabled {
  cursor: not-allowed !important;
  pointer-events: all;
}

.maxWidth {
  width: 100% !important;
}

.redLinks {
  color: #ff7f32 !important;
  text-decoration: none !important;
  /*font-family: "transat-textate", sans-serif;*/
  font-weight: 700 !important;
  font-size: 12px;
}

.anchorLink {
  color: #ff7f32 !important;
  text-decoration: none !important;
  /*font-family: "transat-textate", sans-serif;*/
  font-weight: 700 !important;
  font-size: 16px;
}

/* Color */

.invisible {
  opacity: 0 !important;
}

.blue {
  color: #002855;
}

.grey {
  color: #d8dad9;
}

.opaque {
  opacity: 0 !important;
}

.required {
  color: red;
}

.requiredField {
  color: red;
  font-size: 16px;
}

.fadeInandOut {
  animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Global Styles */

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths,
.col-xl-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 1366px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1650px) {
  .col-xl-5ths {
    width: 20%;
    float: left;
  }
}

.boxShadow {
  -webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}

/* Scrollbar Section */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ebebeb;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-appearance: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hideDiv {
  animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

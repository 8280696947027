.loginRow {
  padding: 0 15px !important;
}

.loginCol1Div {
  margin: auto;
}

@media (min-width: 768px) {
  .loginCol1Div {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .loginDisclaimerDiv {
    /* position: absolute;
    margin: auto;
    width: 40%;
    left: 0;
    right: 0; */
  }
}

.loginDisclaimerDiv {
  margin: auto;
  left: 0;
  right: 0;
  /* padding-right: 15px;
  padding-left: 15px; */
}

@media (min-width: 1366px) {
  .loginDisclaimerDiv {
    bottom: 140px;
    position: absolute;
    width: 40%;
    left: 0;
    right: 0;
  }
  .loginDisclaimerText {
    padding: 0 50px;
  }
}

@media (max-width: 1366px) {
  .loginDisclaimerDiv {
    padding-top: 30px;
  }
  .loginDisclaimerText {
    margin-bottom: 60px;
  }
}

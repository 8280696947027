.registrationMainDiv {
  padding-bottom: 140px !important;
}

@media (min-width: 1366px) {
  .registrationCol1 {
    padding-right: 5% !important;
  }

  .registrationCol2 {
    padding-left: 5% !important;
  }
}

@media (min-width: 1366px) {
  .registrationRow {
    width: 90% !important;
  }
}

.registrationLoginLink {
  color: #ff7f32 !important;
  text-decoration: underline !important;
  text-align: right;
  display: block;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .registrationLoginLink {
    text-align: left;
  }
}

.registrationText {
  margin-top: 15px;
}

.regsitrationSubDiv {
  padding: 60px 0 45px 0;
}

.regsitrationSubText {
  padding: 10px 0;
}

.regsitrationSubSpan {
  color: #b5bbce !important;
}

.agreementHeader {
  margin-bottom: 30px;
}

.agreementCol {
  margin: 15px 0;
}

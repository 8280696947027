/* Form Inputs */

.formDiv {
  max-width: 800px;
  margin: auto;
}

.required {
  color: red;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

.formRow > * {
  margin: 15px 0;
}

.inputCol {
  padding: 20px 0 !important;
}

.inputSelectCol {
  padding: 5px 0 20px 0 !important;
}

.inputStandardLabelCol {
  margin: 0;
}

.inputStandardLabel {
  color: rgba(23, 27, 32, 0.5);
  font-weight: 500 !important;
  margin: 5px 0 0 0;
  font-size: 14px;
}

.inputStandardCol {
  margin-top: 3px;
  padding-bottom: 15px;
}

.checkInputCol {
  padding: 0 15px 10px 15px;
}

/* Form Inputs */

.formInputs {
  width: 100%;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid #ff7f32;
  background: transparent;
  outline: none;
  color: #000000;
  font-size: 20px;
  caret-color: #28caa1;
  font-family: "roboto", serif !important;
  font-weight: 400;
  /* font-style: italic; */
  -webkit-appearance: none !important;
}

.formInputsSelect {
  width: 100%;
  padding: 2px 5px 0px 0px;
  border: none;
  border-bottom: 1px solid #ff7f32;
  background: transparent;
  outline: none;
  color: #000000;
  font-size: 16px;
  caret-color: #28caa1;
  font-family: "roboto", serif !important;
  font-weight: 400;
  /* font-style: italic; */
  -webkit-appearance: none !important;
}

.inputFieldDatePicker {
  width: 100%;
  /* padding: 15px 15px 15px 0; */
  border: none;
  border-bottom: 1px solid #ff7f32;
  background: transparent;
  outline: none;
  color: #000000;
  font-size: 20px;
  caret-color: #28caa1;
}

.react-datepicker-wrapper {
  width: 100%;
  margin-top: -30px;
}

.formInputsLabel {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #999;
  transition: 0.5s;
  pointer-events: none;
  width: 100%;
}

.formInputs::placeholder {
  color: #b5bbce;
  font-size: 16px;
  font-family: "roboto", serif !important;
  font-weight: 700 !important;
}

.formInputs:focus::-webkit-input-placeholder {
  opacity: 0;
}

.formInputs:focus,
.inputFieldDatePicker:focus {
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7f32;
}

.formInputs:hover,
.inputFieldDatePicker:hover {
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7f32;
}

.formInputsTextArea {
  width: 100%;
  padding: 15px;
  border-radius: 7.5px;
  height: 150px;
  border: 1px solid #ff7f32;
  margin-top: 20px;
  outline: none;
  color: #171b20;
}

.formInputsTextArea::placeholder {
  color: rgba(23, 27, 32, 0.5);
}

.formInputsTextArea:focus,
.formInputsTextArea:hover {
  border: 1px solid #ff7f32 !important;
}

.formInputError {
  color: red;
}

.submitButton {
  float: right;
}

.submitButton:disabled {
  cursor: not-allowed;
}

/* Select */

.selectLabel {
  /* position: "absolute",
  left: !customLabel && "0px",
  top: !isFocused && !standardLabel ? 40 : 5, */
  font-size: 14px;
  padding-bottom: 0;
  color: #b5bbce;
  text-transform: uppercase;
  font-family: "roboto", serif !important;
  font-weight: 400;
  margin: 0 !important;
}

/* Checkbox */

.checkboxInputCol {
  margin-top: -15px;
  /* z-index: 999; */
}

.checkboxLabel {
  position: absolute;
  left: 0;
  top: -5 !important;
  /* font-size: 14 !important; */
  color: #b5bbce !important;
  transition-duration: 0.4s;
  font-weight: 700 !important;
  text-transform: "uppercase";
}

.checkboxDiv {
  /* display: flex;
	flex-wrap: wrap; */
  padding-top: 30px;
}

/* The container */
.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 100;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #000000;
  transition-duration: 0.4s;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  border: 1px solid #000000;
  transition-duration: 0.4s;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  border: 1px solid #000000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 6px;
  top: 0px;
  width: 6px;
  height: 13px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxRow {
  width: 100%;
}

/* Radio Button */

.radioInputCol {
  padding: 10px 0 20px 0 !important;
}

.radio-custom {
  opacity: 0;
  position: absolute;
}

.radioLabel {
  position: absolute;
  left: 0;
  top: -5 !important;
  /* font-size: 14 !important; */
  color: #b5bbce !important;
  transition-duration: 0.4s;
  font-weight: 700 !important;
  text-transform: "uppercase";
}

.radio-custom,
.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 35px 0 0 0;
  cursor: pointer;
}

.radio-custom-label {
  margin-right: 15px;
}

.radio-custom-label {
  position: relative;
  color: rgba(23, 27, 32, 0.5);
  outline: none !important;
}

.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border-radius: 0;
  border: 1px solid rgba(23, 27, 32, 0.5);
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-right: 10px;
  text-align: center;
}

.radio-custom + .radio-custom-label:before:hover {
  border: 1px solid #28caa1 !important;
}

.radio-custom:checked + .radio-custom-label:before {
  content: url("./img/checkmark.png");
  color: #000000;
  border: 1px solid #000000;
  line-height: 0px;
}

.radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; /* focus style */
}

/* Dropzone Section */

.dzu-dropzone {
  height: 50px;
  border: 1px solid #b5bbce !important;
}

.dzu-dropzone::-webkit-scrollbar {
  display: none;
}

.dzu-inputLabelWithFiles,
.dzu-inputLabel {
  color: #b5bbce !important;
  font-family: "roboto", serif !important;
}

@media (max-width: 768px) {
  .dzu-inputLabel {
    padding: 0 15px;
  }
}

.dropzoneLabel {
  /* position: "absolute",
  left: !customLabel && "0px",
  top: !isFocused && !standardLabel ? 40 : 5, */
  font-size: 14;
  padding-bottom: 0;
  color: #b5bbce;
  text-transform: uppercase;
  font-family: "roboto", serif !important;
  font-weight: 700;
}

/* HTML Editor */

.public-DraftEditor-content[contenteditable="true"] {
  border: 1px solid #b5bbce !important;
  border-radius: 5px;
}

.rdw-editor-toolbar {
  border: 1px solid #b5bbce !important;
}

/* Phone Input */

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none !important;
  outline: none !important;
}

/* Show Password Eye Icon */

.showPasswordIcon {
  position: absolute;
  top: 15px;
  right: 15px;
}

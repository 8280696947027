/* Not logged in Navbar */

#menu {
  padding: 100px 0 !important;
  background: none !important;
  z-index: 2;
}

@media (min-width: 768px) AND (max-width: 1439px) {
  #menu {
    padding: 30px 0 !important;
  }
}

@media (max-width: 768px) {
  #menu {
    padding: 30px 0 !important;
  }
}

@media (max-height: 767px) {
  #menu {
    padding: 15px 0 !important;
  }
}

.navbarBrand {
  color: #ff7f32 !important;
  font-size: 18px !important;
}

.logo {
  width: 200px;
  height: auto;
}

@media (max-width: 768px) {
  .logo {
    width: 150px;
  }
}

/* Logged in Navbar */

.loggedInLogo {
  width: auto;
  height: 50px;
}

/* @media (min-width: 768px) {
  .loggedInLogo {
    margin-left: 100px;
  }
} */

@media (max-width: 768px) {
  .loggedInLogo {
    margin-top: 30px !important;
    width: 200px;
    height: auto;
    margin: auto auto 30px auto;
  }
}

#loggedInmenu {
  background-color: #002855;
  padding: 15px 140px;
}

#loggedInmenu .navbar-nav {
  margin: 10px auto;
}

@media (max-width: 1440px) {
  #loggedInmenu {
    padding: 0px 30px;
  }
}

@media (max-width: 768px) {
  #loggedInmenu {
    padding: 15px 30px;
  }
}

.loggedInNavbarBrand {
  color: #ffffff !important;
  font-size: 18px !important;
}

@media (max-width: 768px) {
  .loggedInNavbarBrand {
    font-size: 14px !important;
  }
}

.navbarBrandRedUnderline {
  display: block;
  height: 3px;
  background-color: #ff7f32;
  /* width: 85%; */
  margin-top: 0px;
}

.loggedInNavbarBrand:hover {
  color: #ff7f32 !important;
}

@media (max-width: 768px) {
  .navbarBrandRedUnderlineAdmin {
    display: block;
    height: 6px;
    background-color: #ff7f32;
    width: 50%;
    margin: 5px auto;
  }
}

.logoutButton {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none !important;
}

.adminButton {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .adminButton {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .logoutButton,
  .adminButton {
    font-size: 14px !important;
  }

  .linkButton {
    margin: 15px auto !important;
    text-align: center;
    font-size: 24px;
  }
}

.linkButton {
  color: #ffffff !important;
}

.linkButton:hover {
  color: #ff7f32 !important;
}

.navbar-toggler {
  border: none !important;
  padding: 0 !important;
  outline: none !important;
}

.menuSpan {
  width: 35px;
  height: 3px;
  background-color: #ff7f32;
  margin: 3px 0;
}

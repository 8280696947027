.privacyDiv {
  padding-bottom: 140px !important;
}

.privacyText {
  font-family: "transat-text", sans-serif;
  font-weight: 400 !important;
}

.privacySubHeader {
  font-family: "transat-text", serif;
  font-weight: 700;
  font-style: normal;
  margin: 60px 0 30px 0;
}

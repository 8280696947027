.dealCardDiv {
  border: 1px solid rgba(112, 112, 112, 0.26);
  padding: 15px;
  margin-bottom: 60px;
}

.dealCardImg {
  /* background-size: cover !important; */
  /* background-repeat: no-repeat !important;
  background-position: center !important; */
  width: 100%;
  height: 300px;
}

@media (max-width: 1366px) {
  .dealCardImg {
    height: 200px;
  }
}

.dealCardHeader {
  font-size: 24px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .dealCardInfoDiv {
    padding: 15px 0;
  }
}

.dealCardInfoDiv {
  /* padding: 0 15px 15px 15px; */
}

.dealCardInfoRow {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0 !important;
}

.dealCardInfoRowBottom {
  border-bottom: none !important;
}

.dealCardInfoCol {
  padding: 0 !important;
}

.dealCardInfoText {
  margin-bottom: 5px;
  font-family: "roboto", sans-serif;
}

@media (max-width: 768px) {
  .dealCardInfoTextLeft {
    margin-right: 15px;
  }
}

@media (min-width: 768px) {
  .dealCardInfoTextRight {
    float: right;
  }
}

@media (max-width: 1366px) {
  .dealCardInfoTextRight {
    text-align: left;
  }
}

.dealCardInfoButtonRow {
  margin-top: 30px;
}

.dealCardInfoButtonCol {
  padding: 0 15px !important;
}

.dealCardInfoBtn {
  width: 100% !important;
}

.nonDisclosureText {
  font-size: 12px;
  text-align: center;
  margin: 15px 0 0 0 !important;
}

.dealRow {
  margin-top: 30px !important;
}

/* .react-multi-carousel-item--active {
  margin-left: -15px !important;
} */

.react-multi-carousel-dot button {
  margin-bottom: 60px;
}

@media (max-width: 1366px) {
  .react-multi-carousel-dot button {
    margin-bottom: 15px;
  }
}

/* .react-multi-carousel-track {
  margin-left: -10px !important;
} */

.dealImgSubDiv {
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100% !important;
  /* height: 600px !important; */
}

.dealImgDiv {
  border: 1px solid rgba(112, 112, 112, 0.26) !important;
}

@media (max-width: 1366px) {
  .dealImgSubDiv {
    /* height: 250px !important; */
  }
}

/* Summary Information */

.dashboardBackButton {
  font-size: 24px;
  padding: 60px 0 0 0;
  text-decoration: none !important;
  display: inline-block;
  color: #002855;
}

.dashboardBackButton:hover {
  color: #ff7f32;
}

@media (max-width: 768px) {
  .dealSummaryDiv {
    padding-bottom: 30px;
  }
}

.dealHeader {
  font-size: 48px;
  margin: 30px 0;
}

@media (max-width: 1366px) {
  .dealHeader {
    font-size: 32px;
  }
}

.dealCol2 {
  padding: 0 30px !important;
}

@media (max-width: 1366px) {
  .dealCol2 {
    padding: 0 15px !important;
  }
}

.dealInfoDiv {
  border: 1px solid rgba(112, 112, 112, 0.26);
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

@media (max-width: 1366px) {
  .dealInfoDiv {
    padding: 0 15px;
  }
}

.dealDocumentHeader {
  color: #6b6b6b;
  margin: 15px 0 0 0;
}

.dealDocumentRow {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0 !important;
}

.paperclip {
  width: 20px;
  height: auto;
  margin-top: -5px;
  margin-left: 5px;
}

.dealDocumentLink {
  display: block;
  color: #408bf9;
  text-decoration: none !important;
}

.dealDocumentLink:hover {
  color: #ff7f32;
}

/* Image Carousel Dots */

li.react-multi-carousel-dot > * {
  background-color: transparent !important;
}

/* .react-multi-carousel-dot .react-multi-carousel-dot--active {
  background-color: #ffffff !important;
} */

.react-multi-carousel-dot--active button {
  background: #ff7f32 !important;
}

.react-multi-carousel-dot button {
  border-width: 1px !important;
  border-color: #707070 !important;
  padding: 0 !important;
}

.dealInvestNowBtn {
  margin-bottom: 30px;
}

@media (max-width: 1366px) {
  .dealDocumentCol {
    padding: 0 !important;
  }
}

.ndaHeader {
  margin-top: 30px;
  font-size: 32px;
  line-height: 32px;
}

.ndaLink {
  display: block;
  color: #ff7f32;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: 700;
}

.ndaButton {
  margin: 30px 0;
}

.generalNDAButton {
  background-color: transparent !important;
  color: #ff7f32 !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: auto;
  padding: 5px 10px !important;
  font-size: 14px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
  transition-duration: 0.4s;
  margin-right: 30px;
}

.generalNDAButton:hover {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  transition-duration: 0.4s;
}

.generalNDAButtonInverted {
  background-color: #ff7f32 !important;
  color: #ffffff !important;
  border: 1px solid #ff7f32 !important;
  border-radius: 15px !important;
  width: auto;
  padding: 5px 10px !important;
  font-size: 14px !important;
  font-family: "transat-text", sans-serif;
  font-weight: 700 !important;
  transition-duration: 0.4s;
  margin-right: 30px;
}

.generalNDAButtonInverted:hover {
  background-color: #ffffff !important;
  color: #ff7f32 !important;
  transition-duration: 0.4s;
}

.ndaRedirectText {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
}

.dealInvestNowBtn {
  margin-top: 30px;
}

.ndaLogo {
  width: 250px;
  height: auto;
  margin: 60px auto;
  display: block;
}

/* Docusign */

#docusignIFrame {
  width: 100%;
  height: 500px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* Agent Listings */

.dealAgentListing > b {
  margin: 0;
  font-family: "roboto", sans-serif;
}

.dealAgentListing {
  margin: 0;
  font-family: "roboto", sans-serif;
}

.dealAgentListingEmail {
  text-decoration: none !important;
}

.dealErrorHeader {
  text-align: center;
  margin-top: 60px;
  font-size: 42px;
}

.carousel-container {
  width: 100%;
}

.dashboardMain {
  /* padding: 60px 0 !important; */
}

.dashboardIntroHeader {
  font-size: 18px;
  padding: 30px 0;
}

.dashboardNoDealsIntroHeader {
  padding: 60px 0 0 0;
}

.dashboardDealCardCol {
}

/* Footer Section */

.dashboardFooterMainDiv {
  overflow-y: hidden;
}

.dashboardFooterBorderDiv {
  border-top: 1px solid rgba(203, 32, 38, 0.2);
  margin-top: 100px;
}

@media (max-width: 1366px) {
  .dashboardFooterBorderDiv {
    margin-top: 60px;
  }
}
